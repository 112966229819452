.diplomaWrapper {
    background-image: url(../../assets/default/images/diploma/background.png);
    background-size: cover;
    width: 794px;
    height: 1122px;
    position: relative;
    overflow: hidden;
}
.headerImg {
    position: absolute;
    height: 208px;
    width: 794px;
    z-index: 20;
}
.cupImg {
    position: absolute;
    z-index: 10;
    left: 0;
    top: -10px;
    width: 794px;
    height: 669px;
}
.diplomaTable {
    position: absolute;
    left: 34px;
    top: 502px;
    width: 725px;
    height: 353px;
    border-collapse: collapse;
    border-spacing: 0px 0px;
    z-index: 6;
}
.tableRow {
    background: #fff;
    height: 117px;
    border-bottom: 2px solid #bfe2e9;
}
.tableRow:nth-child(1) {
    background: #bfe2e9;
}

.firstColumn {
    width: 15%;
}
.diplomaIconImg {
    width: 70px;
    margin-top: 15px;
    margin-left: 15px;
}
.userIconImg {
    height: 70px;
    margin-top: 15px;
    margin-left: 15px;
}

.firstParagraph {
    font-family: 'SairaExtraCondensed-Bold', serif;
    font-size: 55px;
    color: #522583;
    font-style: italic;
    font-weight: 900;
}
.paragraph {
    font-family: 'Saira-Medium', serif;
    font-size: 55px;
    color: #662a7f;
    font-style: italic;
}

.firstNumber {
    font-family: 'SairaExtraCondensed-Bold', serif;
    padding-right: 20px;
    font-style: italic;
    text-align: right;
    font-size: 55px;
    color: #522583;
}
.secondNumber {
    font-family: 'Saira-Medium', serif;
    padding-right: 20px;
    font-style: italic;
    text-align: right;
    font-size: 55px;
    color: #522583;
}
.thirdNumber {
    font-family: 'Saira-Medium', serif;
    padding-right: 20px;
    font-style: italic;
    text-align: right;
    font-size: 55px;
    color: #522583;
}

.scanAndCollect {
    width: 100%;
    height: 20%;
    position: absolute;
    bottom: 0;
}

.qrWrapper {
    padding: 5px 5px 0 5px;
    position: absolute;
    bottom: 50px;
    right: 33px;
    background: white;
}
