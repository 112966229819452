.logoContainer {
    position: relative;
    /* z-index: 1; */
    width: 100%;
    height: 7vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    margin-bottom: 10px;
}

.logo {
    height: 70%;
    margin-top: 2%;
    margin-left: 5%;
}

.birthdayContainer {
    position: relative;
    top: -50%;
    /* z-index: -1; */
    /* width: 50%; */
    width: max-content;
    height: 170%;
    border-radius: 20px;
    background-color: #ffee00;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 5%;
    padding-left: 8%;
}

.birthdayInsideContainer {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 4%;
    /* border: 2px solid black; */
    margin-bottom: 2%;
    /* margin-left: 5%; */
    /* margin-right: 5%; */
}

.totalText {
    font-family: 'Owners', 'SairaCondensed-Black';
    font-size: 2.4vh;
    color: #6f1bd0;
    align-self: flex-end;
    margin-bottom: 3%;
}

.totalPoints {
    font-family: 'Owners', 'SairaCondensed-Black';
    font-size: 3.8vh;
    color: #6f1bd0;
}

.iconContainer {
    position: relative;
    bottom: 28%;
    height: 55%;
    display: flex;
}

.icon {
    position: relative;
    height: 100%;
    width: auto;
}

.paginationContainer {
    position: relative;
    top: -50%;
    width: 50%;
    height: 170%;
    border-radius: 20px;
    background-color: #ffee00;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.paginationDots {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4%;
    /* border: 2px solid black; */
    margin-bottom: 2%;
}

.dot {
  width: 1.3vh;
  height: 1.3vh;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #6f1bd0;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.5s;
}

.dotActive {
  width: 2vh;
  height: 2vh;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #6f1bd0;
  background-color: #6f1bd0;
  transition: all 0.5s;
}
