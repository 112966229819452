.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.rankingHeader {
    width: 100%;
    height: 40px;
    height: 5vh;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    background-color: #ffee00;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerTitle {
    font-size: 3.5vh;
    font-family: 'Owners', 'SairaCondensed-Black';
    font-style: italic;
    color: #6f1bd0;
    display: flex;
    justify-content: center;
    align-items: center;
}
