@font-face {
    font-family: 'SairaExtraCondensed-Black';
    src: local('Saira'),
        url('./assets/default/fonts/Saira_Extra_Condensed/SairaExtraCondensed-Black.ttf')
            format('truetype');
}
@font-face {
    font-family: 'SairaExtraCondensed-Bold';
    src: local('Saira'),
        url('./assets/default/fonts/Saira_Extra_Condensed/SairaExtraCondensed-Bold.ttf')
            format('truetype');
}
@font-face {
    font-family: 'SairaCondensed-Black';
    src: local('Saira'),
        url('./assets/default/fonts/Saira_Condensed/SairaCondensed-Black.ttf')
            format('truetype');
}
@font-face {
    font-family: 'Saira-SemiBold';
    src: local('Saira'),
        url('./assets/default/fonts/Saira/ttf/Saira-SemiBold.ttf')
            format('truetype');
}
@font-face {
    font-family: 'Saira-Regular';
    src: local('Saira'),
        url('./assets/default/fonts/Saira/ttf/Saira-Regular.ttf')
            format('truetype');
}
@font-face {
    font-family: 'Saira-Medium';
    src: local('Saira'),
        url('./assets/default/fonts/Saira_Extra_Condensed/SairaExtraCondensed-Medium.ttf')
            format('truetype');
}
@font-face {
    font-family: 'Owners';
    src: local('Owners'),
        url('./assets/default/fonts/Owners/otf/owners-text-2.otf')
            format('opentype');
}

html {
    font-size: 25px;
    cursor: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

body {
    margin: 0;
}
