.line {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: top 1500ms, left 1500ms; */
    transition: top 1500ms, left 400ms;
}

.lineWrapper {
    /* width: 98%; */
    height: 100%;
    /* position: relative; */
    display: flex;
    align-items: center;
    /* border: 2px solid black; */
}

.leaderNumberContainer {
    width: 7%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-left: 2%;
}

.leaderNumber {
    font-family: 'Owners', 'SairaCondensed-Black';
    font-size: 2.2vh;
    color: #6f1bd0;
    font-style: italic;
}

.iconContainer {
    display: flex;
    width: 12%;
    height: 65%;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 100%;
}

.barWrapper {
    /* width: 70%; */
    flex: 1;
    height: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #6f1bd0;
    border-radius: 15px;
    overflow: hidden;
}

.bar {
    background-color: #f01e9b;
    height: 100%;
    transition: width 800ms;
}

.score {
    font-family: 'Owners', 'SairaCondensed-Black';
    font-size: 2.5vh;
    color: #6f1bd0;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.lightningIconContainer {
    width: 6%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2%;
}

.lightningIcon {
    height: 50%;
}
