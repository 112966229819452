.leaderboardWrapper {
    background: linear-gradient(
        rgba(12, 253, 224, 1) 0%,
        rgba(111, 27, 208, 1) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    overflow-x: hidden;
}
.containerWrapper {
    width: 85%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resultsBackground {
    width: 100%;
    flex: 1;
    background: #fff;
    position: relative;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding-bottom: 10px;
    z-index: 3;
}

.results {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
}

/* Unused */
.transition {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
}
.video {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
}
