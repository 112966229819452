.container {
    width: 100vw;
    height: 100vh;
    /* background-color: blueviolet; */
    background-image: url('../../../assets/default/images/tag-heroez/score_BG.png');
    background-size: 100vw 100vh;
    display: flex;
    flex-flow: column;
    /* align-items: center; */
    overflow: hidden;
}

.transition {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    z-index: 3;
}

.hero_container {
    width: 100%;
    height: 85%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    /* border: 2px solid black; */
}

.hero_name {
    position: relative;
    width: 35vh;
    top: 2%;
}

.hero_png {
    position: relative;
    height: 75%;
    z-index: 2;
    bottom: 2%;
    animation: disappear 2s ease;
}

.icon_big {
    position: relative;
    height: 60%;
    z-index: 2;
    margin-top: 10%;
    animation: appear 2s ease;
}

.score_container {
    width: 95%;
    height: 15%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* border: 2px solid black; */
    padding-bottom: 2%;
}

.icon_with_progress_bar {
    flex: 1;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 2px solid black; */
}

.icon {
    position: relative;
    height: 80%;
    left: 10%;
    bottom: 5%;
}

.score_bar_wrapper {
    /* flex: 1; */
    width: 100%;
    height: 55%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #6f1bd0;
    border-radius: 25px;
    overflow: hidden;
    /* border: 2px solid #000; */
}

.bar {
    background-color: #f01e9b;
    height: 100%;
    transition: width 2000ms;
}

.score {
    width: 25%;
    display: flex;
    justify-content: center;
    gap: 10%;
    /* border: 2px solid black; */
    font-family: 'Owners', 'SairaCondensed-Black';
    font-size: 4vh;
    color: #6f1bd0;
    margin-left: 3%;
}

.scoreIcon {
    width: 4vh;
}

@keyframes appear {
    from {
        scale: 0;
    }

    30%,
    to {
        scale: 1;
    }
}

@keyframes disappear {
    from,
    75% {
        scale: 1;
    }

    90%,
    to {
        scale: 0;
    }
}
