.leaderboardWrapper {
    background-image: url('../../../assets/default/images/FlashTest/green-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 100vh;
}
.containerWrapper {
    width: 90%;
}

.headerWrapper {
    margin: 2% 2% 2% -5%;
    width: 110%;
    background-image: url('../../../assets/default/images/FlashTest/header.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.countdown {
    width: 20%;
    text-align: center;
    margin-left: -5%;
    font-family: 'SairaExtraCondensed-Black';
    font-size: 21.5vw;
    line-height: 21.5vw;
    color: #ffffff;
    font-style: italic;
    text-shadow: 4px 1px #ff0, 20px 10px 1px rgba(100, 20, 204, 1);
}

.brawo {
    padding-top: 4%;
}

.resultsBackground {
    background: #ffffff;
    position: relative;
    width: 100%;
}

.resultsBackgroundLine {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.results {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    background: white;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.line {
    display: flex;
    align-items: center;
    padding-left: 2%;
}
.lineWrapper {
    width: 90%;
    position: absolute;
    display: flex;
    transition: top 2000ms;
    margin-left: 5%;
}
.leaderNumber {
    text-align: center;
    font-family: 'SairaExtraCondensed-Black';
    line-height: 1.3;
    font-size: 1.4rem;
    color: rgb(100, 20, 204);
    font-style: italic;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.digit {
    padding-right: 15%;
}
.score {
    text-align: right;
    font-family: 'SairaExtraCondensed-Black';
    padding-left: 2%;
    font-size: 1.9rem;
    color: rgb(100, 20, 204);
    font-style: italic;
    width: 6%;
    position: relative;
}
.scorePoints {
    padding-right: 35%;
}

.barWrapper {
    width: 86%;
    height: 40%;
    margin-top: 2.5%;
    position: relative;
}

.backgroundBarWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.backgroundBar {
    background-color: #e6398f;
    opacity: 0.251;
    height: 100%;
    width: 100%;
}

.backgroundTriangle {
    opacity: 0.251;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #e6398f transparent transparent transparent;
}

.scoreBarWrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.scoreBar {
    background-color: #fc119a;
    height: 100%;
    transition: width 800ms;
}
.scoreTriangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #fc119a transparent transparent transparent;
}
.icon {
    padding: 1% 2%;
}
.tick {
    padding-top: 30%;
    padding-left: 2%;
}
.explosion {
    position: absolute;
    top: 0;
    right: -45%;
}
.scoreNumber {
    position: absolute;
    top: 0;
    right: 0;
}
.logoContainer {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
}
.logo {
    width: 38%;
    height: 82%;
    margin-top: 2%;
}
.transition {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 5;
}
