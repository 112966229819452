.container {
    position: relative;
    width: 100vw;
    height: 100vh;
    /* background-color: blueviolet; */
    background-image: url('../../../assets/default/images/tag-heroez/countdown_BG.png');
    background-size: 100vw 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@keyframes countdown {
    0% {
        top: -100%;
    }
    25% {
        top: 25%;
    }
    75% {
        top: 25%;
    }
    100% {
        top: 100%;
    }
}

.count_1 {
    position: absolute;
    top: 100%;
    width: 30vh;
    animation: countdown 1.5s;
}

.count_2 {
    position: absolute;
    top: 100%;
    width: 40vh;
    animation: countdown 1.5s;
}

.graj {
    width: 110%;
    visibility: hidden;
}

.transition {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: fill;
    visibility: hidden;
    /* z-index: 3; */
}
