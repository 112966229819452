.wrapper {
    background-color: blueviolet;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageWrapper {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.transition {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: fill;
}
